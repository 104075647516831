/* You can add global styles to this file, and also import other style files */
@import "assets/css/bootstrap.min.css";
@import "assets/css/doctorstyle.css";

/*============================================== Global ================================*/

html, body { height: 100%; }
body { margin: 0; }

img {
  max-width: 100%;
  /*height: auto;*/
}

::-webkit-scrollbar {
  width:5px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.simple-custom-select .simple-custom-input {
  font-family: iranyekan-regular sans-serif !important;
  font-size: 0.875rem !important;
}

.time-picker .mat-form-field-infix {
  display: flex;
}

.timepicker__header, .clock-face__clock-hand, .clock-face__number > span.active {
  background-color: #79b2b2 !important;
}

.timepicker__actions {
  justify-content: flex-start !important;
}

.clock-face__container {
  margin-left: 97px !important;
}

.timepicker-dial__time {
  direction: ltr;
}

.request-badge {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #272b41;
}

.btn-white:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

/*========================================== Custom classes ============================================*/

.pointer {
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #b3b3b3;
}

.half-divider {
  width: 50%;
  height: 1px;
  background-color: rgba(179, 179, 179, 0.5);
}

.text-hover-primary:hover {
  color: #09e5ab !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.online-border {
  border: 2px solid #00e65b;
}

.form-errors {
  font-size: 12px;
  color: red;
}

.uploader-text {
  max-width: 40%;
}

.width-control {
  max-width: 45%;
}

.bold-title {
  font-size: 14px;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.card-bg {
  background-color: #F5F5F5 !important;
}

.disable {
  opacity: 0.4;
}

.btn-red-link {
  background-color: transparent;
  border: none;
  outline: none;
  color: rgba(177, 78, 78, 1);
}

.btn-red-link:hover {
  color: rgba(231, 146, 68, 1);
}

.btn-red-link:disabled {
  opacity: 0.6;
  color: #6c757d;
}

.btn-main-link {
  background-color: transparent;
  border: none;
  outline: none;
  color: rgba(60, 91, 108, 1);
}

.btn-main-link:hover {
  color: rgb(71, 108, 128);
}

.btn-main-link:disabled {
  color: rgba(60, 91, 108, 0.6);
}

.btn-primary-link {
  background-color: transparent;
  border: none;
  outline: none;
  color: #00e65b;
}

.btn-primary-link:hover {
  color: #028134;
}

.bg-light-blue {
  background-color: rgba(53, 134, 152, 0.15);
}

.btn-light-success {
  color: rgba(12, 179, 167, 0.9);
  background-color: rgba(231, 248, 247, 0.8);
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.975rem;
}

.btn-light-success:hover {
  color: rgba(12, 179, 167, 1);
  background-color: rgba(231, 248, 247, 1);
}

.btn-light-danger {
  color: rgba(255, 135, 135, 0.9);
  background-color: rgba(255, 227, 225, 0.7);
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.975rem;
}

.btn-light-danger:hover {
  color: rgba(255, 135, 135, 1);
  background-color: rgba(255, 227, 225, 1);
}

.bg-E7F1F1 {
  background-color: #E7F1F1;
}

.text-midnight {
  color: rgb(60, 91, 108);
}

.internal-accordion-box {
  background-color: white;
  padding: 32px 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.fs-normal {
  font-size: 1rem;
}

.fs-small {
  font-size: 0.875rem;
}

.min-w-40 {
  min-width: 40%;
}

.main-color {
  color: rgb(60 91 108);
}

.request-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: rgba(163, 234, 208, 1);
  color: rgba(60, 91, 108, 1);
  margin-top: 16px;
}

.request-bar span {
  font-weight: bold;
  font-size: 14px;
}

.request-bar button {
  color: rgba(60, 91, 108, 1);
  font-weight: bold;
  font-size: 14px;
  border: 1px solid rgba(60, 91, 108, 1);
  display: flex;
  align-items: center;
}

/*=========================================== Customize Material =======================================*/

.mat-dialog-container {
  padding: 0 !important;
}

.response-dialog .mat-dialog-container {
  padding: 0 !important;
}

.mat-dialog-content {
  margin: 0 !important;
  padding: 16px !important;
}

.mat-dialog-content::-webkit-scrollbar {
  width:1px !important;
}

.mat-dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.mat-dialog-content::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.mat-dialog-content::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.mat-drawer-content::-webkit-scrollbar {
  width: 1px !important;
}

.mat-drawer-content::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.mat-drawer-content::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.mat-drawer-content::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.nested-accordion .mat-expansion-panel-body {
  padding: 0 24px 16px 0 !important;
}

.nested-accordion .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.nested-accordion .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.nested-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: rgba(0,0,0,0) !important;
}

.nested-accordion .mat-expansion-panel {
  border-bottom: none !important;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0,0,0,0.75);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0,0,0,0.65);
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0,0,0,0.65);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0,0,0,0.65);
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0,0,0,0.75);
}

/* =============================================== Media queries ======================================= */

/*@media (min-width: 300px) and (max-width: 399.98px) {*/
/*  .mat-dialog-container {*/
/*    min-width: 250px !important;*/
/*    max-width: 90% !important;*/
/*    margin: auto !important;*/
/*  }*/
/*}*/

/*@media (min-width: 400px) and (max-width: 499.98px) {*/
/*  .mat-dialog-container {*/
/*    min-width: 350px !important;*/
/*    max-width: 90% !important;*/
/*    margin: auto !important;*/
/*  }*/
/*}*/

/*@media (min-width: 500px) and (max-width: 599.98px) {*/
/*  .mat-dialog-container {*/
/*    min-width: 450px !important;*/
/*    max-width: 90% !important;*/
/*    margin: auto !important;*/
/*  }*/
/*}*/

@media (min-width: 600px) and (max-width: 767.98px) {
  .mat-dialog-container {
    min-width: 550px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (max-width: 768px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
    width: auto !important;
  }

  .mat-table[matTableResponsive] .mat-header-row {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 2;
    min-height: 60px;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 1rem 0.5rem;
    justify-content: space-around;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell > .mat-sort-header-container {
    padding-left: 15px;
  }
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell:not([mat-sort-header]),
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell.mat-sort-header-disabled,
  .mat-table[matTableResponsive] .mat-header-row th.mat-header-cell.mat-column-actions {
    display: none;
  }
  .mat-table[matTableResponsive] .mat-row {
    height: auto;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions) {
    padding: 0.5rem;
    word-break: break-word;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions) > * {
    margin: 0 6px;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-cell:not(.mat-column-actions):before {
    content: attr(data-column-name);
    text-align: left;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-column-actions {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
  .mat-table[matTableResponsive] .mat-row td.mat-column-actions > button {
    margin: 0.5rem 0;
  }
  .mat-table[matTableResponsive] .mat-row:last-of-type td:last-of-type {
    border: none;
  }
  .mat-table[matTableResponsive] .mat-row:not(:last-of-type) td:last-of-type {
    border-bottom: 0.5rem solid #fafafa;
  }
  .mat-card .mat-table[matTableResponsive], .mat-expansion-panel .mat-table[matTableResponsive] {
    border: 0.5rem solid #fafafa;
  }

}

@media (min-width: 768px) {
  .main-logo {
    width: 70%;
  }

  .uploader-text {
    max-width: 60%;
  }

  .width-control {
    max-width: 70%;
  }
}

@media (min-width: 300px) and (max-width: 399.98px) {
  .mat-dialog-container {
    min-width: 250px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (min-width: 400px) and (max-width: 499.98px) {
  .mat-dialog-container {
    min-width: 350px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (min-width: 500px) and (max-width: 599.98px) {
  .mat-dialog-container {
    min-width: 450px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (min-width: 600px) and (max-width: 767.98px) {
  .mat-dialog-container {
    min-width: 550px !important;
    max-width: 90% !important;
    margin: auto !important;
  }
}

@media (max-width: 767.98px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .mat-dialog-container {
    min-width: 500px;
  }
}

@media (max-width: 992px) {
  .full-width-dialog {
    max-width: 100vw !important;
    width: 96% !important;
  }
}

@media (min-width: 992px) {
  .request-badge {
    top: 10px;
  }
}
